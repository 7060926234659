import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import SideNavButton from "./SideNavButton";
import { signOut } from "firebase/auth";
import { auth } from "../Auth/firebase";

export default function SideNav() {
  let currUrl = window.location.href.split("/");
  if (
    currUrl[currUrl.length - 1] !== "Analytics" &&
    currUrl[currUrl.length - 1] !== "Exams"
  ) {
    currUrl = ["Analytics"];
  }
  const [selected, setSelected] = useState(currUrl[currUrl.length - 1]);
  const navigate = useNavigate();
  function handleLogOut() {
    signOut(auth);
    localStorage.removeItem("user");
    window.scrollTo(0, 0);
    navigate("/");
  }

  function handleExam() {
    navigate("/Dashboard/Exams");
    setSelected("Exams");
  }

  function handleAnalytics() {
    navigate("/Dashboard/Analytics");
    setSelected("Analytics");
  }

  function handleSubscribe() {
    navigate("/Pricing");
  }
  return (
    <div className="side-nav-div">
      <div className="side-nav">
        <div className="side-nav-logo-div">
          <Logo
            color={false}
            text={false}
            style={{ margin: "15px 40px 5px 40px" }}
            width="35%"
          />
        </div>
        <div className="side-nav-divider"></div>
        <div className="welcome">Welcome Back</div>
        <SideNavButton
          onClick={handleExam}
          text="Exams"
          selected={selected !== "Exams"}
        />
        <SideNavButton
          onClick={handleAnalytics}
          text="Analytics"
          selected={selected !== "Analytics"}
        />
        {sessionStorage.getItem("paid") === "true" ? (
          <SideNavButton
            target={"_blank"}
            href="https://billing.stripe.com/p/login/eVabKla5y8cqfde7ss"
            text="Subscription"
            selected={true}
          />
        ) : (
          <SideNavButton
            onClick={handleSubscribe}
            text="Subscribe"
            selected={true}
          />
        )}

        <SideNavButton
          className="log-out-button"
          text="Log Out"
          selected={true}
          onClick={() => handleLogOut()}
        />
      </div>
    </div>
  );
}
