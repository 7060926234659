import React from "react";
import { Button } from "@mui/material";

export default function Rules(props) {
  return (
    <div className="rules-container">
      <div className="rules-title">Rules and Information</div>
      <div className="rules">
        The following is a 100 multiple-choice question exam meant to simulate
        popular business competitions.
        <ol>
          <li>There is no penalty for guessing.</li>
          <li>
            There is an optional 70-minute time limit, shown by a timer counting
            down on the top right.
          </li>
          <li>
            Answers will be automatically marked upon test completion and all
            answer reasoning will be shown.
          </li>
          <li>
            In a formal setting, changing screens is not allowed, so have a
            physical calculator ready for calculations.
          </li>
        </ol>
        <div>
          Additional information: For business competitions in teams, the total
          score for the multiple-choice test is usually taken as an average of
          team members, and is worth ½ of the total score. Individual events
          usually have multiple presentations, so the multiple-choice test is
          worth ⅓ of the total score.
        </div>
      </div>
      <Button
        variant="contained"
        onClick={props.startExam}
        sx={{
          mt: 2,
          mb: 5,
          width: "100%",
          backgroundColor: "#00a9ac",
          fontFamily: "rubik",
        }}
      >
        Start
      </Button>
    </div>
  );
}
