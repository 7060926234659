import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import ExamMCQuestion from "./ExamMCQuestion";

const API_URL_SCORE = "https://lit-education-5a5f9cbf986c.herokuapp.com/api/score/";
const API_URL_HEAT_MAP = "https://lit-education-5a5f9cbf986c.herokuapp.com/api/heat-map/";

let currAnswers = Array(100).fill("E");

export default function ExamMCQuestions(props) {
  const [loading, setLoading] = useState(false);

  function handleAnswer(event, index) {
    currAnswers[index] = event.target.value;
  }

  async function handleSubmit() {
    setLoading(true);
    let today = new Date();
    let todayString = today.toISOString().split("T")[0];
    let score = 0;
    for (let i = 0; i < props.questions.length; i++) {
      if (props.questions[i].correct_answer === currAnswers[i]) score += 1;
    }
    let sendExam = axios.post(API_URL_SCORE + "completed-exam/add", {
      examName: props.examName,
      email: JSON.parse(localStorage.getItem("user")).email,
      answers: currAnswers,
      totalScore: score,
    });
    let sendHeatMap = axios.post(API_URL_HEAT_MAP + "heat-map/add", {
      email: JSON.parse(localStorage.getItem("user")).email,
      score: score,
      date: todayString,
    });
    await axios
      .all([sendExam, sendHeatMap])
      // TODO: add values
      .then(axios.spread((...responses) => {}))
      .catch((error) => {
        console.log(error);
      });
    window.location.reload(false);
    setLoading(false);
    window.scrollTo(0, 0);
  }
  return (
    <>
      {props.questions.map((question, index) => (
        <ExamMCQuestion
          key={index}
          question={question.question}
          answers={question.possible_answers}
          questionNumber={index + 1 + "."}
          onChange={(event) => handleAnswer(event, index)}
        />
      ))}
      <div className="submit-button-div">
        <Button
          variant="contained"
          disabled={loading}
          sx={{
            mt: 2,
            mb: 5,
            width: "100%",
            backgroundColor: "#00a9ac",
            fontFamily: "rubik",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
