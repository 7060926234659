import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import ExamMC from "./components/ExamMC/ExamMC";
import FirebaseLogin from "./components/Auth/FirebaseLogin";
import FirebaseSignup from "./components/Auth/FirebaseSignup";
import Analytics from "./components/Dashboard/Analytics/Analytics";
import TotalExams from "./components/Dashboard/Exam/TotalExams";
import MainPage from "./components/MainPage/MainPage";
import Pricing from "./components/Pricing/Pricing";

export default function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/Pricing" element={<Pricing />} />
        <Route exact path="/Dashboard" element={<Dashboard />}>
          <Route index element={<Analytics />} />
          <Route
            exact
            path="/Dashboard/Analytics"
            element={<Analytics />}
          ></Route>
          <Route exact path="/Dashboard/Exams" element={<TotalExams />}></Route>
        </Route>
        <Route exact path="/ExamMC" element={<ExamMC />}></Route>
        <Route exact path="/Login" element={<FirebaseLogin />}></Route>
        <Route exact path="/Signup" element={<FirebaseSignup />}></Route>
      </Routes>
    </div>
  );
}
