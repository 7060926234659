import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import HeaderMain from "../Header/HeaderMain";
import HeaderPricing from "../Header/HeaderPricing";
import Footer from "../Footer/Footer";

function PricingContent() {
  let user = JSON.parse(localStorage.getItem("user"));
  const tiers = [
    {
      title: "Free",
      price: "0",
      description: [
        "1 exam per category",
        "Limited Analytic Tracking",
        "Help center access",
        "Email support",
      ],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
      buttonRef: "/Signup",
    },
    {
      title: "Pro",
      subheader: "Most popular",
      price: "20",
      description: [
        "Access to all exams",
        "Full analytic Tracking",
        "Instant test results",
        "Priority email support",
      ],
      buttonText: "Get started",
      buttonVariant: "contained",
      buttonRef: user
        ? "https://buy.stripe.com/6oE15D3IBg4523K5kl?prefilled_email=" +
          user.email
        : "https://buy.stripe.com/6oE15D3IBg4523K5kl",
    },
    {
      title: "School",
      price: "_",
      description: [
        "All Pro Features",
        "Discount per student",
        "Teacher Analytic View",
        "Monitor Student Usage",
      ],
      buttonText: "Contact us",
      buttonVariant: "outlined",
      buttonRef: "mailto:info.litedu@gmail.com",
    },
  ];
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      {/* Hero unit */}
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ mt: 10, pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          fontFamily="rubik"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          fontFamily="rubik"
          component="p"
        >
          Get started with your business preparation right away! All
          subscriptions are monthly and provide unrestricted access to all
          LearnIt Education features.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end" sx={{ mb: 10 }}>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "School" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: "#00a9ac",
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                      fontFamily="rubik"
                    >
                      ${tier.price}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      fontFamily="rubik"
                    >
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        fontFamily="rubik"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 5,
                      width: "100%",
                      backgroundColor: "#1d7b94",
                      fontFamily: "rubik",
                      color: "#ffffff",
                    }}
                    href={tier.buttonRef}
                    target={"_blank"}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default function Pricing() {
  return (
    <>
      {JSON.parse(localStorage.getItem("user")) ? (
        <HeaderPricing />
      ) : (
        <HeaderMain />
      )}
      <PricingContent />
    </>
  );
}
