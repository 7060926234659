import React, { useEffect } from "react";
import { Avatar } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ProvideItem(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      data-aos={props.left ? "fade-right" : "fade-left"}
      className="provide-feature"
    >
      {!props.left && <div className="provide-feature-use">{props.use}</div>}
      <div className="provide-feature-header">
        <Avatar
          sx={{
            m: 1,
            marginTop: "5vh",
            width: "3.5rem",
            height: "3.5rem",
            bgcolor: props.color,
          }}
        >
          {props.icon}
        </Avatar>
        <div className="provide-feature-title" style={{ color: props.color }}>
          {props.title}
        </div>
        <div className="provide-feature-information">{props.info}</div>
      </div>
      {props.left && <div className="provide-feature-use">{props.use}</div>}
    </div>
  );
}
