import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDtrP21832HS1gOZd3Ranh5-0djGhuF8sA",
  authDomain: "learnit-eduation.firebaseapp.com",
  projectId: "learnit-eduation",
  storageBucket: "learnit-eduation.appspot.com",
  messagingSenderId: "285920908441",
  appId: "1:285920908441:web:243c56aa5933a5bd179408",
  measurementId: "G-XMG3ZRYN5J",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export function firebaseErrorMessage(e) {
  switch (e) {
    case "auth/email-already-in-use":
      return "Email already in use";
    case "auth/claims-too-large":
      return "Maximum claim size reached";
    case "auth/id-token-expired":
      return "Token expired, please refresh and try again";
    case "auth/internal-error":
      return "There was an internal error";
    case "auth/invalid-argument":
      return "Invalid arguments provided";
    case "auth/invalid-email":
      return "Please provide a valid email";
    case "auth/wrong-password":
      return "Incorrect Password";
    case "auth/user-not-found":
      return "No account with this email";
    default:
      return e;
  }
}
