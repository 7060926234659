import React from "react";
import Logo from "../Header/Logo";
import { signOut } from "firebase/auth";
import { auth } from "../Auth/firebase";
import { useNavigate } from "react-router-dom";

function FooterExam(props) {
  const navigate = useNavigate();
  function handleLogOut() {
    signOut(auth);
    localStorage.removeItem("user");
    window.scrollTo(0, 0);
    navigate("/");
  }
  return (
    <footer id="exam-footer" className="footer-container">
      <section className="footer-copyright">
        <Logo className="footer-logo" width="10vh" color={true} sx={{}} />
        <p className="footer-copyright-text">
          © {new Date().getFullYear()} LearnIt Education. All Rights Reserved
        </p>
      </section>
      <section className="footer-links">
        <a
          target={"_blank"}
          href="https://www.instagram.com/learnit.education/"
          rel="noreferrer"
        >
          <img
            className="media-icon"
            src="/images/icons/instagram.svg"
            alt="instagram"
          />
        </a>
        <div className="footer-link" onClick={() => handleLogOut()}>
          Log Out
        </div>
      </section>
    </footer>
  );
}

export default FooterExam;
