import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export default function MCLineGraph(props) {
  const labels = props.scoreData.map((completedExam) => {
    return completedExam.exam_name;
  });
  const dataInfo = props.scoreData.map((completedExam) => {
    return completedExam.total_score;
  });
  const data = {
    labels: labels,
    datasets: [
      {
        label: "MC Exam Scores",
        borderColor: "rgb(0, 169, 172)",
        data: dataInfo,
      },
    ],
  };
  return (
    <div className="mc-line-graph-grid">
      <div className="mc-graph-div">
        <div className="graph-sizing">
          <Line
            data={data}
            options={{
              scales: {
                x: {
                  ticks: {
                    color: "#000",
                    font: {
                      size: 12,
                      family: "Rubik",
                    },
                  },
                },
                y: {
                  ticks: {
                    color: "#000",
                    font: {
                      size: 12,
                      family: "Rubik",
                    },
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Previous MC Exam Scores",
                  color: "#000000",
                  font: {
                    size: 20,
                    family: "Rubik",
                  },
                },
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    </div>
  );
}
