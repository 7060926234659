import React from "react";
import { Button } from "@mui/material";

export default function SideNavButton(props) {
  let style = props.selected
    ? {
        marginTop: "20px",
        width: "80%",
        color: "#fff",
        borderColor: "#fff",
        fontFamily: "rubik",
        fontSize: "1.2rem",
        "&:hover": {
          borderColor: "#ccc",
          color: "#000",
        },
      }
    : {
        marginTop: "20px",
        width: "80%",
        borderColor: "#fff",
        backgroundColor: "#fff",
        fontFamily: "rubik",
        color: "#000",
        fontSize: "1.2rem",
        "&:hover": {
          backgroundColor: "#ccc",
        },
      };
  return (
    <Button
      className={"side-nav-button " + props.className}
      {...(!props.selected
        ? { variant: "contained" }
        : { variant: "outlined" })}
      size="large"
      sx={style}
      onClick={props.onClick}
      target={props.target}
      href={props.href}
    >
      {props.text}
    </Button>
  );
}
