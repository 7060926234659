import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ExamHeader from "./ExamHeader";

export default function ExamType(props) {
  return (
    <Accordion
      style={{
        width: "95%",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        style={{
          padding: "10px 20px 10px 5%",
          borderBottom: "2px solid black",
        }}
      >
        <span
          style={{
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          {props.examTypeName}
        </span>
      </AccordionSummary>
      <AccordionDetails style={{ marginTop: "25px" }}>
        {props.examList ? (
          props.examList.map((exam) => (
            <ExamHeader
              key={exam.exam_name}
              examName={exam.exam_name}
              score={exam.score}
            />
          ))
        ) : (
          <div />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
