import React from "react";
import "aos/dist/aos.css";

export default function WhoWeAre() {
  return (
    <div className="who-we-are-container" data-aos="fade-up">
      <div className="who-we-are who-we-are-img-container">
        <img
          src="/images/who-we-are.png"
          alt="Who we are"
          className="who-we-are-image"
        />
      </div>
      <div className="who-we-are who-we-are-info-container">
        <div className="who-we-are-title">Who we are</div>
        <div className="who-we-are-info">
          LearnIt Education is an up and coming tutoring service that currently
          focuses on improving an individual's ability to perform in business
          competitions. We have plans to branch out into other pertinent extra
          curricular activities and eventually become a leader in online
          education overall.
        </div>
      </div>
    </div>
  );
}
