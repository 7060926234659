import React from "react";
import Logo from "./Logo";
import TimerIcon from "@mui/icons-material/Timer";
import Timer from "./Timer";

export default function HeaderExam(props) {
  let timer = props.timer ? (
    <>
      <TimerIcon sx={{ fontSize: "2.8vw", color: "#fff" }} /> <Timer />
    </>
  ) : (
    <>
      <TimerIcon sx={{ fontSize: "2.8vw", color: "#fff" }} />
      <span className="timer">70:00</span>
    </>
  );
  return (
    <header>
      <nav className="header-main">
        <Logo className="header-logo" width="10vh" color={true} sx={{}} />
        <div className="exam-nav-item exam-mc-title">{props.examName}</div>
        <div className="exam-nav-item timer-div">
          {!Number.isInteger(props.score) ? (
            timer
          ) : (
            <span className="timer">{props.score + "/100"}</span>
          )}
        </div>
      </nav>
    </header>
  );
}
