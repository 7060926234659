import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/LockPerson";
import Copyright from "../Copyright";
import * as yup from "yup";
import { auth, firebaseErrorMessage } from "./firebase";

const theme = createTheme();

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const signinSchema = yup.object().shape({
    password: yup.string().required("Please input a password"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please input an email"),
  });

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    signinSchema
      .validate({ email: email, password: password })
      .then(() =>
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            setErrorMessage("");
            const userStorage = { email: userCredential.user.email };
            localStorage.setItem("user", JSON.stringify(userStorage));
            props.history.push("/Dashboard");
            window.location.reload();
          })
          .catch((error) => {
            const errorCode = error.code;
            setLoading(false);
            setErrorMessage(firebaseErrorMessage(errorCode));
          })
      )
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err.message);
      });
  };

  const user = auth.currentUser;
  if (user !== null) {
    return <Navigate to="/Dashboard" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="auth login">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#00a9ac" }}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <Box
            component="form"
            onSubmit={handleLogin}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={onChangeEmail}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={onChangePassword}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <div className="auth-error-message">
              {errorMessage ? errorMessage : ""}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 0,
                mb: 2,
                backgroundColor: "#00a9ac",
                fontFamily: "rubik",
              }}
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Log In</span>
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Continue with Google
                </Link> */}
              </Grid>
              <Grid item>
                <Link href="/Signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 3, mb: 3 }} />
      </Container>
    </ThemeProvider>
  );
}
