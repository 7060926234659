import { Link } from "react-router-dom";
import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "./Logo";

let style = {
  borderColor: "#fff",
  backgroundColor: "#fff",
  color: "#000",
  fontSize: "1.2rem",
  fontFamily: "rubik",
  width: "120px",
  height: "40%",
  "&:hover": {
    backgroundColor: "#ccc",
  },
};

export default function HeaderPricing() {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header>
      <nav className="header-main">
        <Logo className="header-logo" width="10vh" color={true} sx={{}} />
        {isSmallScreen ? (
          <>
            <IconButton onClick={handleClick}>
              <MenuIcon style={{ fill: "white" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              // transformOrigin={{
              //   vertical: "top",
              //   horizontal: "left",
              // }}
            >
              <MenuItem onClick={handleClose}>
                <Link className="header-link" to="/Dashboard/Exams">
                  <Button sx={style}>EXAMS</Button>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link className="header-link " to="/Dashboard/Analytics">
                  <Button sx={style}>ANALYTICS</Button>
                </Link>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <ul className="nav-items">
            <li className="nav-item">
              <Link className="header-link" to="/Dashboard/Exams">
                <Button sx={style}>EXAMS</Button>
              </Link>
            </li>
            <li className="nav-item ">
              <Link className="header-link " to="/Dashboard/Analytics">
                <Button sx={style}>ANALYTICS</Button>
              </Link>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
}
