import React from "react";
import { RadioGroup, FormControl } from "@mui/material";
import FormValue from "./FormValues";

export default function ExamMCQuestion(props) {
  const letters = ["A", "B", "C", "D"];
  return (
    <div className={"exam-mc-question " + props.className}>
      <div className="exam-mc-question-question">
        <span className="exam-mc-question-number">{props.questionNumber}</span>
        {props.question}
      </div>
      <div className="exam-mc-answers">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={props.onChange}
          >
            {letters.map((letter, index) => (
              <FormValue
                key={letter}
                letter={letter}
                answer={props.answers[index]}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}
