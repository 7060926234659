import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ExamMCReview from "./ExamMCReview";

export default function ExamMCReviews(props) {
  let navigate = useNavigate();
  function handleLeave() {
    navigate("/Dashboard/Exams");
  }

  return (
    <>
      {props.questions.map((question, index) => (
        <ExamMCReview
          key={index}
          question={question.question}
          answers={question.possible_answers}
          questionNumber={index + 1 + "."}
          studentAnswer={props.completedexam.answers[index]}
          correctAnswer={question.correct_answer}
          reasoning={question.reasoning}
        />
      ))}
      <div className="submit-button-div">
        <Button
          variant="contained"
          sx={{
            mt: 2,
            mb: 5,
            width: "100%",
            backgroundColor: "#00a9ac",
            fontFamily: "rubik",
          }}
          onClick={handleLeave}
        >
          Go Back
        </Button>
      </div>
    </>
  );
}
