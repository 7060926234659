import React from "react";
import { useNavigate } from "react-router-dom";

export default function Logo(props) {
  const navigate = useNavigate();

  let srcValue = "";
  if (props.color && props.text) {
    srcValue = "/logos/logo-colored-text.png";
  } else if (props.color && !props.text) {
    srcValue = "/logos/logo-colored.png";
  } else if (!props.color && props.text) {
    srcValue = "/logos/logo-black-text.png";
  } else {
    srcValue = "/logos/logo-black.png";
  }

  return (
    <div
      className={"logo-div " + props.className}
      style={props.style}
      onClick={() => {
        navigate("/");
      }}
    >
      <img
        className="logo"
        style={{ maxWidth: props.width }}
        src={srcValue}
        alt="Logo"
      ></img>
      <div className="logo-text">
        <span
          className="logo-bold"
          style={{ ...(!props.color && { color: "#000" }) }}
        >
          LearnIt
        </span>
        <br />
        <span
          className="logo-italic"
          style={{ ...(!props.color && { color: "#000" }) }}
        >
          Education
        </span>
      </div>
    </div>
  );
}
