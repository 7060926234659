import React from "react";
import HeaderMain from "../Header/HeaderMain";
import TitleDiv from "./TitleDiv";
import Provide from "./Provide";
import Footer from "../Footer/Footer";
import WhoWeAre from "./WhoWeAre";

export default function MainPage() {
  return (
    <div>
      <HeaderMain />
      <TitleDiv />
      <WhoWeAre />
      <Provide />
      <Footer />
    </div>
  );
}
