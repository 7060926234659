import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import SideNav from "../Header/SideNav";
import { auth } from "../Auth/firebase";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";

const STRIPE_API_KEY =
  "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";

const stripeApiConfig = {
  headers: { Authorization: `Bearer ${STRIPE_API_KEY}` },
};

export default function Dashboard() {
  const navigate = useNavigate();
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      navigate("/");
    }
  });

  if (!JSON.parse(localStorage.getItem("user"))) {
    navigate("/");
  }

  let email = JSON.parse(localStorage.getItem("user")).email;

  async function isPaid() {
    sessionStorage.setItem("paid", false);
    axios
      .get(
        "https://api.stripe.com/v1/customers/search?query=email: '" +
          email +
          "'&expand[0]=data.subscriptions",
        stripeApiConfig
      )
      .then((response) => {
        let paid = false;
        let subscriptions = response.data.data[0].subscriptions.data;
        for (let i = 0; i < subscriptions.length; i++) {
          if (sessionStorage.getItem("paid") === "true") {
            paid = true;
            break;
          }
          let items = subscriptions[i].items.data;
          for (let j = 0; j < items.length; j++) {
            if (items[j].plan.active === true) {
              paid = true;
              sessionStorage.setItem("paid", true);
              break;
            }
          }
        }
        if (paid === false) {
          sessionStorage.setItem("paid", false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (sessionStorage.getItem("paid") !== "true") {
      isPaid();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard">
      <SideNav />
      <Outlet />
    </div>
  );
}
