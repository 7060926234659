import React, { useEffect, useState } from "react";
import HeaderExam from "../Header/HeaderExam";
import Rules from "./Rules";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ExamMCQuestions from "./ExamMCQuestions";
import ExamMCReviews from "./ExamMCReviews";
import FooterExam from "../Footer/FooterExam";

const API_URL_EXAM = "https://lit-education-5a5f9cbf986c.herokuapp.com/api/exam/";
const API_URL_SCORE = "https://lit-education-5a5f9cbf986c.herokuapp.com/api/score/";

export default function ExamMC() {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [questions, setQuestions] = useState([]);
  const [score, setScore] = useState({});
  const [started, setStarted] = useState(false);

  const requestQuestions = axios.get(
    API_URL_EXAM + "fetch-questions/" + location.state.examName
  );
  const requestScore = axios.get(
    API_URL_SCORE +
      "fetch-scores-exam/" +
      location.state.examName +
      "/" +
      user.email
  );

  const getQuestionsandScore = async () => {
    await axios
      .all([requestQuestions, requestScore])
      .then(
        axios.spread((...responses) => {
          const questionData = responses[0].data;
          setQuestions(questionData);
          const scoreData = responses[1].data;
          setScore(scoreData);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  };

  function startExam() {
    setStarted(true);
  }

  useEffect(() => {
    getQuestionsandScore();
    // eslint-disable-next-line
  }, []);

  if (!location.state) {
    return <Navigate to="/Dashboard" />;
  }

  const incomplete = !score;

  return (
    <div className="exam-mc">
      <HeaderExam
        score={incomplete ? null : score.total_score}
        timer={started}
        examName={location.state.examName}
      />
      {incomplete ? (
        !started ? (
          <Rules examName={location.state.examName} startExam={startExam} />
        ) : (
          <ExamMCQuestions
            questions={questions}
            examName={location.state.examName}
          />
        )
      ) : (
        <ExamMCReviews questions={questions} completedexam={score} />
      )}
      <FooterExam rules={incomplete && !started} />
    </div>
  );
}
