import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import ReactTooltip from "react-tooltip";

const API_URL_HEAT_MAP = "https://lit-education-5a5f9cbf986c.herokuapp.com/api/heat-map/";

export default function MCActivityCalendar(props) {
  const [heatMapData, setHeatMapData] = useState([]);

  let today = new Date();
  let sixMonths = new Date();
  sixMonths.setDate(sixMonths.getDate() - 180);

  let values = heatMapData === [] ? [] : heatMapData;
  const getHeatMap = useCallback(async () => {
    await axios
      .get(
        API_URL_HEAT_MAP +
          "fetch-heat-map/" +
          JSON.parse(localStorage.getItem("user")).email
      )
      .then((response) => {
        const heatMapData = response.data.daily_answers;
        setHeatMapData(heatMapData);
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getHeatMap();
    ReactTooltip.rebuild();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mc-activity-calendar">
      <div className="mc-graph-div mc-calendar-div">
        <div className="calendar-title">MC Exam History</div>
        <div className="calendar-heatmap-container">
          <div className="calendar-heatmap">
            <CalendarHeatmap
              startDate={sixMonths}
              endDate={today}
              tooltipDataAttrs={(value) => {
                let dataTip = "";
                if (!value.date) {
                  dataTip = "No questions done";
                } else {
                  dataTip = `${value.date}: ${value.count} Correct Answers`;
                }
                return {
                  "data-tip": dataTip,
                };
              }}
              gutterSize={2}
              values={values}
              classForValue={(value) => {
                if (!value) {
                  return "color-empty";
                }
                return `color-scale-${Math.ceil(value.count / 50)}`;
              }}
            />
            <ReactTooltip />
          </div>
        </div>
      </div>
    </div>
  );
}
