import { Box } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function ChapterWide() {
  return (
    <div className="chapter-wide-container">
      <Box className="chapter-wide">
        <img
          className="chapter-wide-image"
          src="/images/people/Ameen.jpg"
          alt="Cuties <3"
        />
        <div className="chapter-wide-username-container">
          <div className="chapter-wide-username">Ameen Parthab</div>
          <div className="chapter-wide-analytics">View Analytics</div>
        </div>
        <ArrowForwardIosIcon
          sx={{ fontSize: "1.5vw", marginLeft: "2vw", color: "#000" }}
        />
      </Box>
      <Box className="chapter-wide">
        <img
          className="chapter-wide-image"
          src="/images/people/Jacob.png"
          alt="Cuties <3"
        />
        <div className="chapter-wide-username-container">
          <div className="chapter-wide-username">Jacob Liu</div>
          <div className="chapter-wide-analytics">View Analytics</div>
        </div>
        <ArrowForwardIosIcon
          sx={{ fontSize: "1.5vw", marginLeft: "2vw", color: "#000" }}
        />
      </Box>
      <Box className="chapter-wide">
        <img
          className="chapter-wide-image"
          src="/images/people/Cindy.png"
          alt="Cuties <3"
        />
        <div className="chapter-wide-username-container">
          <div className="chapter-wide-username">Cindy Chen</div>
          <div className="chapter-wide-analytics">View Analytics</div>
        </div>
        <ArrowForwardIosIcon
          sx={{ fontSize: "1.5vw", marginLeft: "2vw", color: "#000" }}
        />
      </Box>
    </div>
  );
}
