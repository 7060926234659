import React from "react";
import { FormControlLabel, Radio } from "@mui/material";

export default function FormValue(props) {
  const incorrectStyle = {
    backgroundColor: "rgba(255,0,0,.3)",
    borderRadius: "3px",
    paddingRight: "10px",
  };
  const correctStyle = {
    backgroundColor: "rgba(0,255,0,.3)",
    borderRadius: "3px",
    paddingRight: "10px",
  };
  let currStyle = {};
  if (props.green) {
    currStyle = correctStyle;
  } else if (props.red) {
    currStyle = incorrectStyle;
  } else {
    currStyle = {};
  }
  return (
    <div style={currStyle} className="exam-mc-answer">
      <div className="exam-mc-answer-letter-div">
        <span className="exam-mc-answer-letter">{props.letter}.</span>
      </div>
      <FormControlLabel
        value={props.letter}
        control={<Radio />}
        disabled={props.disabled}
        checked={props.checked}
      />
      <div className="exam-mc-answer-answer-div">
        <span className="exam-mc-ancswer-answer">{props.answer}</span>
      </div>
    </div>
  );
}
