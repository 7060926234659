import React from "react";
import ProvideItem from "./ProvideItem";
import MCRadarGraph from "../Dashboard/Analytics/MCRadarGraph";
import ArticleIcon from "@mui/icons-material/Article";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExamMCQuestion from "../ExamMC/ExamMCQuestion";
import GroupsIcon from "@mui/icons-material/Groups";
import ChapterWide from "./ChapterWide";

export default function Provide() {
  const provideQuestion = {
    title: "Hundreds of Questions",
    info: "Gain access to our current repository of over 10000 unique business competition multiple choice questions with more being added every single day. Each question has a detailed explanation for its answer.",
    icon: <ArticleIcon sx={{ fontSize: "2rem" }} />,
    use: (
      <ExamMCQuestion
        className="feature-mc-question"
        question="Is LearnIt Education Amazing?"
        answers={["Absolutely!", "Yes!", "Most Certainly!", "Definitely!"]}
      />
    ),
  };

  const provideAnalytics = {
    title: "In-Depth Analytics",
    info: "Track your progress with numerous visuals and statistics that accurately monitor and record your progress. Additional features, including automated feedback, to be implemented soon.",
    icon: <AssessmentIcon sx={{ fontSize: "2rem" }} />,
    use: <MCRadarGraph className="feature-radar-graph" />,
  };

  const provideChapter = {
    title: "Chapter-Wide Monitoring",
    info: "Purchase as an individual or chapter. Chapters get teacher views, chapter wide tracking and access to special in-house events/competitions run monthly.",
    icon: <GroupsIcon sx={{ fontSize: "2rem" }} />,
    use: <ChapterWide />,
  };

  return (
    <div className="provide">
      <div className="provide-title" data-aos="fade-up">
        What We Provide
      </div>
      <ProvideItem
        data-aos="fade-up"
        title={provideQuestion.title}
        info={provideQuestion.info}
        icon={provideQuestion.icon}
        use={provideQuestion.use}
        color={"#a5d423"}
        left={true}
      />
      <ProvideItem
        title={provideAnalytics.title}
        info={provideAnalytics.info}
        icon={provideAnalytics.icon}
        use={provideAnalytics.use}
        color={"#1d7b94"}
      />
      <ProvideItem
        title={provideChapter.title}
        info={provideChapter.info}
        icon={provideChapter.icon}
        use={provideChapter.use}
        color={"#00a9ac"}
        left={true}
      />
    </div>
  );
}
