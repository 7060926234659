import React from "react";
import { RadioGroup, FormControl } from "@mui/material";
import FormValue from "./FormValues";

export default function ExamMCReview(props) {
  const correct = props.studentAnswer === props.correctAnswer;
  const letters = ["A", "B", "C", "D"];

  let reasoningTitle = <div></div>;
  if (correct) {
    reasoningTitle = <div className="reasoning-correct">Correct!</div>;
  } else if (!letters.includes(props.studentAnswer)) {
    reasoningTitle = (
      <div className="reasoning-incorrect">No Answer Given :(</div>
    );
  } else {
    reasoningTitle = <div className="reasoning-incorrect">Incorrect</div>;
  }
  return (
    <div className={"exam-mc-question " + props.className}>
      <div className="exam-mc-question-question">
        <span className="exam-mc-question-number">{props.questionNumber}</span>
        {props.question}
      </div>
      <div className="exam-mc-answers">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
          >
            {letters.map((letter, index) => (
              <FormValue
                key={letter}
                letter={letter}
                answer={props.answers[index]}
                disabled={true}
                checked={props.studentAnswer === letter}
                green={props.correctAnswer === letter}
                red={
                  props.studentAnswer === letter &&
                  props.studentAnswer !== props.correctAnswer
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      {reasoningTitle}
      <div className="reasoning-title">Reasoning:</div>
      <div className="reasoning">{props.reasoning}</div>
    </div>
  );
}
