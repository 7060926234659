import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";

/* A footer to our different pages that sends users to our code, various links and states our copyright.*/
function Footer() {
  return (
    <footer className="footer-container">
      <section className="footer-copyright">
        <Logo className="footer-logo" width="10vh" color={true} sx={{}} />
        <p className="footer-copyright-text">
          © {new Date().getFullYear()} LearnIt Education. All Rights Reserved
        </p>
      </section>
      <section className="footer-links">
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://www.instagram.com/learnit.education/"
        >
          <img
            className="media-icon"
            src="/images/icons/instagram.svg"
            alt="instagram"
          />
        </a>
        <Link className="footer-link" to="/login">
          Log In
        </Link>
        <Link className="footer-link" to="/signup">
          Sign Up
        </Link>
      </section>
    </footer>
  );
}

export default Footer;
