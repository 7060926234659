import React from "react";

export default function TitleDiv() {
  return (
    <div className="title-div">
      <div className="title-div-text">
        <h1 className="title-text">LearnIt Education</h1>
        <h2 className="subtitle-text">
          The ultimate tool for business competition preparation, practice, and
          growth
        </h2>
      </div>
      <div className="image-div-title">
        <div className="title-div-image">
          <img
            className="title-image"
            src="/images/cuties-cropped.png"
            alt="Cuties <3"
          />
        </div>
        <div className="cloud-div">
          <img
            className="cloud cloud-left"
            src="/images/cloud.png"
            alt="Cloud"
          />
          <img
            className="cloud cloud-right"
            src="/images/cloud.png"
            alt="Cloud"
          />
        </div>
      </div>
    </div>
  );
}
